.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
    text-align: center;
    border: 4px solid grey;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: #662288;

    animation: spin 1s ease infinite;
}

h4 {
    margin-block: 0;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }
}