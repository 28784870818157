.FM-form-page {
    width: 100%;
}

.FM-form-page-title {
    color: var(--color);
    font-size: 2.5em;
    font-family: Arial;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FM-form-page-subtitle {
    color: gray;
    font-size: 1em;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}

.FM-form-page-container {
    padding: 10px 0px;
}

.FM-form-error {
    color: darkred;
}